<template>
  <b-form>
    <!-- Personal Data -->
    <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
      <div class="col-span-8">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 sm:col-span-6">
            <input-required
              v-model="viewModel.Nome"
              :label="$t('sectionProviders.personalData.name')"
              disabled
            />
          </div>
          <div class="col-span-12 sm:col-span-6">
            <input-required
              v-model="viewModel.NomeFantasia"
              :label="$t('sectionProviders.personalData.fantasyName')"
              disabled
            />
          </div>
        </div>
      </div>

      <div class="col-span-8 border-solid border-b border-gray-300 pb-2">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 sm:col-span-6">
            <input-required
              v-model="viewModel.CpfCnpj"
              :label="$t('sectionProviders.personalData.document')"
              :mask="['###.###.###-##', '##.###.###/####-##']"
              disabled
            />
          </div>
          <div class="col-span-12 sm:col-span-6">
            <input-required
              v-model="viewModel.InscEstadual"
              :label="$t('sectionProviders.personalData.stateRegistration')"
              mask="###.###.###.###"
              disabled
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Address Data -->
    <div class="grid grid-cols-1 md:grid-cols-12 gap-4 mt-3">
      <div class="col-span-8 border-solid border-b border-gray-300">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 xl:col-span-3">
            <input-default
              v-model="viewModel.endereco.Cep"
              :label="$t('sectionProviders.addressData.zipCode')"
              mask="#####-###"
              disabled
            />
          </div>
          <div class="col-span-12 xl:col-span-7">
            <input-default
              v-model="viewModel.endereco.Rua"
              :label="$t('sectionProviders.addressData.road')"
              disabled
            />
          </div>
          <div class="col-span-12 xl:col-span-2">
            <input-default
              v-model="viewModel.endereco.Numero"
              :label="$t('sectionProviders.addressData.number')"
              placeholder="Nº"
              disabled
            />
          </div>
               <div class="col-span-12 lg:col-span-4">
                <Dropdown 
                  :Items="dropdownItemsEstado"
                  v-model="viewModel.endereco.EstadoId"
                  required="required"
                  :valueDefault="$t('sectionProviders.addressData.state')"
                  :label="$t('sectionProviders.addressData.state')"
                  @ItemSelectId="DropdownSelected($event,'estado')"
                  disabled
                  >
                </Dropdown>
              </div>
               <div class="col-span-12 lg:col-span-4">
                <Dropdown 
                  :Items="dropdownItemsCidade"
                  v-model="viewModel.endereco.CidadeId"
                  :valueDefault="$t('sectionPrescriber.addressData.city')"
                  :label="$t('sectionPrescriber.addressData.city')"
                  @ItemSelectId="DropdownSelected($event,'cidade')"
                  disabled
                  >
                </Dropdown>
              </div>
               <div class="col-span-12 lg:col-span-3">
                 <Dropdown 
                  :Items="dropdownItemsBairro"
                  v-model="viewModel.endereco.BairroId"
                  :valueDefault="$t('sectionPrescriber.addressData.district')"
                  :label="$t('sectionPrescriber.addressData.district')"
                  @ItemSelectId="DropdownSelected($event,'bairro')"
                  disabled
                  >
                </Dropdown>
              </div>
              <div class="col-span-12 xl:col-span-7">
              <input-default
                v-model="viewModel.endereco.Complemento"
                :label="$t('sectionProviders.addressData.complemento')"
              disabled
              />
            </div>
        </div>
      </div>
      <div class="col-span-8"></div>
    </div>
    <!-- Contact Data -->
    <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
      <div class="col-span-8 border-solid border-b border-gray-300">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 lg:col-span-2">
            <input-default
              :value="viewModel.Telefone.length >= 1 ?  viewModel.Telefone[0].ddd : ''"
              :label="$t('sectionProviders.contactData.ddd1')"
              placeholder="(99)"
              mask="(##)"
              disabled
            />
          </div>
          <div class="col-span-12 lg:col-span-4">
            <input-default
              :value="viewModel.Telefone.length >= 1 ?  viewModel.Telefone[0].numero : ''"
              :label="$t('sectionProviders.contactData.phone')"
              placeholder="9999-9999"
              mask="####-####"
              disabled
            />
          </div>
          <div class="col-span-12 lg:col-span-2">
            <input-default
             :value="viewModel.Telefone.length == 2 ?  viewModel.Telefone[1].ddd : ''"
              :label="$t('sectionProviders.contactData.ddd2')"
              placeholder="(99)"
              mask="(##)"
              disabled
            />
          </div>
          <div class="col-span-12 lg:col-span-4">
            <input-default
              :value="viewModel.Telefone.length == 2 ?  viewModel.Telefone[1].numero : ''"
              :label="$t('sectionProviders.contactData.cellPhone')"
              placeholder="9 9999-9999"
              mask="# ####-####"
              disabled
            />
          </div>
        </div>
      </div>
      <div class="col-span-8">
        <input-default
          v-model="viewModel.Email"
          :label="$t('sectionProviders.contactData.email')"
          disabled
        />
      </div>
    </div>
  </b-form>
</template>

<script>
import InputRequired from "@core/components/commons/inputs/InputRequired";
import InputDefault from "@core/components/commons/inputs/InputDefault";
import Dropdown from "@/@core/components/commons/selectDropdown/Dropdown.vue";

import { BForm } from "bootstrap-vue";

export default {
  components: {
    BForm,
    InputRequired,
    InputDefault,
    Dropdown,
  },
  props: {
    viewModel: {
      type: Object,
    },
  },
  data() {
    return {
      dropdownItemsEstado:[],
      dropdownItemsPais:[],
      dropdownItemsBairro:[],
      dropdownItemsCidade:[],
    }
  },
  created() {
    this.ObterSeletorEstado();
  },
  methods: {
    ObterSeletorBairro(cidadeId) {
      this.$http({
        url: `/bairro/obter-seletor-bairro`,
        params: { idCidade: `${cidadeId}` },

        method: "GET"
      }).then((res) => {
          this.dropdownItemsBairro = res.data;
        })
    },
    ObterSeletorEstado() {
      this.$http({
        url: `/estado/obter-seletor-estados`,
        method: "GET"
      }).then((res) => {
          this.dropdownItemsEstado = res.data;
        })
    },
    ObterSeletorCidade(estadoId) {
      this.$http({
        url: `/cidade/obter-seletor-cidades-por-estado/${estadoId}`,
        method: "GET"
      }).then((res) => {
          this.dropdownItemsCidade = res.data;
        })
    },
    DropdownSelected (item, tipo) {
      if(tipo == 'estado') {
        this.ObterSeletorCidade(item);
      } else if (tipo == 'cidade') {
        this.ObterSeletorBairro(item);
      }
    },
  },
};
</script>
