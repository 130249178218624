<template>
  <section>
    <b-form>
      <!-- Bank Account -->
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div class="col-span-8">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-3">
              <Dropdown 
                :Items="dropdownItemsBanco"
                v-model="viewModel.Banco.BancoId"
                :valueDefault="$t('sectionProviders.bankAccount.bank')"
                :label="$t('sectionProviders.bankAccount.bank')"
                disabled
                >
              </Dropdown>
            </div>
            <div class="col-span-12 sm:col-span-9">
               <input-default
                v-model="viewModel.Banco.NomeDoBanco"
                :label="$t('sectionProviders.bankAccount.bankName')"
                disabled
            />
            </div>
          </div>
        </div>

        <div class="col-span-8 border-solid border-b border-gray-300 pb-2">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-3">
              <input-default
                v-model="viewModel.Banco.Agencia"
                :label="$t('sectionProviders.bankAccount.agency')"
                :mask="['XXX', 'XXX-X', 'XXX-XX']"
                disabled
              />
            </div>
            <div class="col-span-12 sm:col-span-9">
              <input-default
                v-model="viewModel.Banco.ContaCorrente"
                :label="$t('sectionProviders.bankAccount.checkingAccount')"
                :mask="['XXX-X', 'XXXX-X', 'XXXXX-X', 'XXXXXX-X']"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Complementary Info -->
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4 mt-3">
        <div class="col-span-8 border-solid border-b border-gray-300">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12">
              <input-default
                v-model="viewModel.Documentacao.ResponsavelTecnico"
                :label="
                  $t('sectionProviders.complementaryInfo.technicalManager')"
                disabled
              />
            </div>
            <div class="col-span-12 xl:col-span-4">
              <input-default
                v-model="viewModel.Documentacao.AlvaraSanitario"
                :label="$t('sectionProviders.complementaryInfo.healthPermit')"
                disabled
              />
            </div>
            <div class="col-span-12 xl:col-span-4">
              <input-default
                v-model="viewModel.Documentacao.AutFuncionamento"
                :label="
                  $t('sectionProviders.complementaryInfo.operatingPermit')
                "
                placeholder="Nº"
                disabled
              />
            </div>
            <div class="col-span-12 lg:col-span-4">
              <input-default
                v-model="viewModel.Documentacao.AutEspecial"
                :label="$t('sectionProviders.complementaryInfo.specialPermit')"
                disabled
              />
            </div>
            <div class="col-span-12 lg:col-span-8">
              <input-default
                v-model="viewModel.Documentacao.LicencaMapa"
                :label="$t('sectionProviders.complementaryInfo.mapLicense')"
                disabled
              />
            </div>
          </div>
        </div>
        <div class="col-span-8"></div>
      </div>
      <!-- Pharma Register -->
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div class="col-span-8 border-solid border-b border-gray-300">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12">
              <input-default
                v-model="viewModel.Farmacia.CadastroFarmacia"
                :label="
                  $t('sectionProviders.pharmaRegister.pharmacyRegistration')
                "
                disabled
              />
            </div>
            <div class="col-span-12 lg:col-span-4">
              <input-default
                v-model="viewModel.Farmacia.HostFTP"
                :label="$t('sectionProviders.pharmaRegister.host')"
                disabled
              />
            </div>
            <div class="col-span-12 lg:col-span-4">
              <input-default
                v-model="viewModel.Farmacia.UsuarioFTP"
                :label="$t('sectionProviders.pharmaRegister.user')"
                disabled
              />
            </div>
            <div class="col-span-12 lg:col-span-4">
              <input-default
                v-model="viewModel.Farmacia.Senha"
                :label="$t('sectionProviders.pharmaRegister.password')"
                placeholder="********"
                disabled
              />
            </div>
            <div class="col-span-12 lg:col-span-4">
              <Dropdown 
                :Items="dropdownItems"
                v-model="viewModel.Farmacia.Conta"
                :valueDefault="$t('sectionProviders.pharmaRegister.account')"
                :label="$t('sectionProviders.pharmaRegister.account')"
                disabled
                >
              </Dropdown>
            </div>
            <div class="col-span-12 lg:col-span-4">
              <input-default
                v-model="viewModel.Farmacia.ValorMinimo"
                :label="$t('sectionProviders.pharmaRegister.minimumValue')"
                disabled
              />
            </div>
            <div class="col-span-12 lg:col-span-8">
              <input-default
                v-model="viewModel.Farmacia.FormaPagamento"
                :label="$t('sectionProviders.pharmaRegister.formPayment')"
                disabled
              />
            </div>
            <div class="col-span-12 lg:col-span-4">
              <input-default
                v-model="viewModel.Farmacia.PrevisaoDeEntrega"
                :label="$t('sectionProviders.pharmaRegister.deliveryForecast')"
                disabled
              />
            </div>
            <div class="col-span-12 lg:col-span-8">
              <input-default
                v-model="viewModel.Farmacia.Frete"
                :label="$t('sectionProviders.pharmaRegister.shipping')"
                disabled
              />
            </div>
          </div>
        </div>
        <div class="col-span-8">
          <input-default
            v-model="viewModel.Farmacia.Observacoes"
            :label="$t('sectionProviders.pharmaRegister.comments')"
            disabled
          />
        </div>
      </div>
    </b-form>
  </section>
</template>

<script type="module">
import InputDefault from "@core/components/commons/inputs/InputDefault";
import Dropdown from "@/@core/components/commons/selectDropdown/Dropdown.vue";

import { BForm } from "bootstrap-vue";

export default {
  name: "SupplementaryInformationSupplier",
  components: {
    BForm,
    InputDefault,
    Dropdown,
  },
  props: {
    viewModel: {
      type: Object,
    },
  },
  data() {
    return {
      dropdownItems: [],
      dropdownItemsBanco: [],
    }
  },
  created () {
    this.ObterSeletorPlanoContas();
    this.ObterSeletorBanco();
  },
  methods: {
    ObterSeletorPlanoContas() {
      this.$http({
        url: "/planoConta/obter-select-plano-conta",
        method: "GET"
      }).then((res) => {
          this.dropdownItems = res.data;
        })
        .catch((erro) => {
          this.loading = false;
          this.$notify({
            data: erro.response.data.erros,
            type: "warn",
            duration: 10000
          });
        });
    },
    ObterSeletorBanco () {
      this.$http({
        url: "/banco/obter-seletor-banco",
        method: "GET"
      }).then((res) => {
          this.dropdownItemsBanco = res.data;
        })
        .catch((erro) => {
          this.loading = false;
          this.$notify({
            data: erro.response.data.erros,
            type: "warn",
            duration: 10000
          });
        });
    },
  }
};
</script>
