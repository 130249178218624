<template>
  <div>
  <section class="-mt-4">
    <b-tabs class="w-full">
      <b-tab title="Geral">
        <GeneralSupplierInformation
          class="mt-8"
          :viewModel.sync="viewModel.geral"
        />
      </b-tab>
      <b-tab title="Complemento">
        <SupplementaryInformationSupplier
          class="mt-8"
          :viewModel.sync="viewModel.complemento"
        />
      </b-tab>
    </b-tabs>
    </section>
    <footer class="w-full flex items-center justify-start gap-5 my-10">
      <Button
        class="bg-gray-900 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-gray-700 transition-all duration-300"
        buttonText="Voltar"
        :onClick="redirectUrl"
      />
    </footer>
  </div>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";

import GeneralSupplierInformation from "./GeneralSupplierInformation";
import SupplementaryInformationSupplier from "./SupplementaryInformationSupplier";
import Button from "@core/components/commons/button/Button";

export default {
  name: "ManageSuppliersView",
  components: {
    BTabs,
    BTab,
    Button,
    GeneralSupplierInformation,
    SupplementaryInformationSupplier,
  },
  data() {
    return {
      viewModel: {
        geral: {
          Nome: null,
          NomeFantasia: null,
          CpfCnpj: null,
          InscEstadual: null,
          Email: null,
          Telefone: [],
          //Endereço
          endereco: {
            Cep: null,
            Rua: null,
            Numero: null,
            Complemento: null,
            BairroId: null,
            CidadeId: null,
            EstadoId: null,
          },
        },
        complemento: {
         Banco: {
          BancoId: null,
          NomeDoBanco: null,
          Agencia: null,
          ContaCorrente: null,
        },
        Documentacao: {
          ResponsavelTecnico: null,
          AlvaraSanitario: null,
          AutEspecial: null,
          LicencaMapa: null,
          AutFuncionamento: null,
        },
        Farmacia: {
          CadastroFarmacia: null,
          HostFTP: null,
          UsuarioFTP: null,
          Senha: null,
          Conta: null,
          ValorMinimo: null,
          FormaPagamento: null,
          PrevisaoDeEntrega: null,
          Frete: null,
          Observacoes: null,
          },
        }
      }
    };
  },
  created () {
    this.RecuperarUser(this.$router.currentRoute.params.id);
  },
  methods: {
    RecuperarUser (id)
    {
      this.$http({
        url: `/fornecedor/obter/${id}`,
        method: "Get"
      }).then((response) => {
        this.viewModel.geral = {
            Nome : response.data.nome,
            NomeFantasia: response.data.nomeFantasia,
            CpfCnpj: response.data.cpfCnpj,
            InscEstadual: response.data.inscEstadual,
            Email : response.data.email,
            Telefone: response.data.telefone,
          //Endereço
          endereco: {
            Cep: response.data.endereco?.cep,
            Rua: response.data.endereco?.rua,
            Numero: response.data.endereco?.numero,
            Complemento: response.data.endereco?.complemento,
            BairroId: response.data.endereco?.bairroId,
            CidadeId: response.data.endereco?.cidadeId,
            EstadoId: response.data.endereco?.estadoId,
          }
        }
        this.viewModel.complemento = {
          Banco: {
            BancoId: response.data.dadosbancarios?.banco,
            NomeDoBanco: response.data.dadosbancarios?.nomeDoBanco,
            Agencia: response.data.dadosbancarios?.agencia,
            ContaCorrente: response.data.dadosbancarios?.contaCorrente,
          },
          Documentacao: {
            ResponsavelTecnico: response.data.documentos?.responsavelTecnico,
            AlvaraSanitario: response.data.documentos?.alvaraSanitario,
            AutEspecial: response.data.documentos?.autEspecial,
            LicencaMapa: response.data.documentos?.licencaMapa,
            AutFuncionamento:  response.data.documentos?.autFuncionamento,
          },
          Farmacia: {
            CadastroFarmacia: response.data.cadastrofarma?.cadastroFarmacia,
            HostFTP: response.data.cadastrofarma?.hostFTP,
            UsuarioFTP: response.data.cadastrofarma?.usuarioFTP,
            Senha: response.data.cadastrofarma?.senhaFTP,
            Conta: response.data.cadastrofarma?.conta,
            ValorMinimo: response.data.cadastrofarma?.valorMinimoPedido,
            FormaPagamento: response.data.cadastrofarma?.formaPagamento,
            PrevisaoDeEntrega: response.data.cadastrofarma?.previsaoEntrega,
            Frete: response.data.cadastrofarma?.frete,
            Observacoes: response.data.cadastrofarma?.observacao,
          },
        }
           this.viewModel.geral.Telefone = [];
            var telefone = response.data.telefone?.filter((item) => item.numero?.length <= 8);
            this.viewModel.geral.Telefone[0] = {
                ddd: telefone[0]?.ddd || null,
                numero: telefone[0]?.numero || null
            }

            var celular = response.data.telefone?.filter((item) => item.numero?.length >= 9);
            this.viewModel.geral.Telefone[1] = {
                ddd: celular[0]?.ddd || null,
                numero: celular[0]?.numero || null
          }
      })
    },
    redirectUrl() {
      return this.$router.push("/fornecedores");
    },
  },
};
</script>

<style></style>
